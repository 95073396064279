import { usePathname } from "next/navigation";

import { AppEnum } from "@/constants";

import {
  isAOSmithDefault,
  isAOSmithLowes,
  isAOSmithStateWaterHeaters,
  isSVCE,
} from "./utils";

export const useDetermineApp = () => {
  const pathname = usePathname();

  const branding = (() => {
    if (isAOSmithDefault(pathname)) {
      return AppEnum.AOSmithDefault;
    }
    if (isAOSmithStateWaterHeaters(pathname)) {
      return AppEnum.AOSmithStateWaterHeaters;
    }
    if (isAOSmithLowes(pathname)) {
      return AppEnum.AOSmithLowes;
    }
    if (isSVCE(location.hostname)) {
      return AppEnum.SVCE;
    }
    return AppEnum.RockRabbit;
  })();

  return {
    branding,
  };
};
