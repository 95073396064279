import {
  ApplicationPhaseEnum,
  IncentiveBundleListIncentivesRequest,
  QuoteRequestCreate,
  SupportedUSStatesEnum,
} from "@/api/BackendAPI";
import { API, client } from "@/api/client";
import {
  AUTH_QUERY_PARAMS,
  PROJECT_CHECKLIST_QUERY_PARAMS,
  WIZARD_QUERY_PARAMS,
} from "@/constants/queryParams";
import { getRefinedQueryParams } from "@/utils/queryParams";

export const GOOGLE_MAPS_API_KEY = "AIzaSyCed_PCtEiLdn2kox7nfnYnwYMwCbId2_c";

export enum AppEnum {
  RockRabbit = "rock_rabbit",
  SVCE = "silicon_valley_clean_energy",
  AOSmithDefault = "ao_smith_default",
  AOSmithStateWaterHeaters = "ao_smith_state_water_heaters",
  AOSmithLowes = "ao_smith_lowes",
}

export const AOS_BRAND_TO_APP_ENUM: Record<
  "aosmith" | "state_water_heaters" | "lowes",
  AppEnum
> = {
  aosmith: AppEnum.AOSmithDefault,
  state_water_heaters: AppEnum.AOSmithStateWaterHeaters,
  lowes: AppEnum.AOSmithLowes,
} as const;

export const CSS_VARIABLES: Record<AppEnum, Record<string, string>> = {
  [AppEnum.RockRabbit]: {
    "--rr__color__primary-25": "#f6fefc",
    "--rr__color__primary-50": "#f0fdf9",
    "--rr__color__primary-100": "#ccfbef",
    "--rr__color__primary-200": "#99f6e0",
    "--rr__color__primary-300": "#5fe9d0",
    "--rr__color__primary-400": "#2ed3b7",
    "--rr__color__primary-500": "#15b79e",
    "--rr__color__primary-600": "#0e9384",
    "--rr__color__primary-700": "#107569",
    "--rr__color__primary-800": "#125d56",
    "--rr__color__primary-900": "#134e48",
    "--rr__color__primary-border-interactive-contained-default": "transparent",
    "--rr__color__primary-border-interactive-contained-hover": "transparent",
    "--rr__color__primary-border-interactive-contained-focus": "transparent",
    "--rr__color__primary-border-interactive-outlined-default": "#a9cdd0",
    "--rr__color__primary-border-interactive-outlined-hover": "#a9cdd0",
    "--rr__color__primary-border-interactive-outlined-focus": "transparent",
    "--rr__color__primary-border-interactive-text-default": "transparent",
    "--rr__color__primary-border-interactive-text-hover": "transparent",
    "--rr__color__primary-border-interactive-text-focus": "transparent",
    "--rr__color__primary-outline-interactive-contained-focus": "#cbe1e3",
    "--rr__color__primary-outline-interactive-outlined-focus": "#cbe1e3",
    "--rr__color__primary-outline-interactive-text-focus": "#cbe1e3",
    "--rr__color__primary-outline-input_control-focus": "#cbe1e3",
    "--rr__color__secondary-25": "#f6fefc",
    "--rr__color__secondary-50": "#f0fdf9",
    "--rr__color__secondary-100": "#ccfbef",
    "--rr__color__secondary-200": "#99f6e0",
    "--rr__color__secondary-300": "#5fe9d0",
    "--rr__color__secondary-400": "#2ed3b7",
    "--rr__color__secondary-500": "#15b79e",
    "--rr__color__secondary-600": "#0e9384",
    "--rr__color__secondary-700": "#107569",
    "--rr__color__secondary-800": "#125d56",
    "--rr__color__secondary-900": "#134e48",
  },
  [AppEnum.SVCE]: {
    "--rr__color__primary-25": "#a9cded",
    "--rr__color__primary-50": "#9abedf",
    "--rr__color__primary-100": "#8bafd0",
    "--rr__color__primary-200": "#6d92b3",
    "--rr__color__primary-300": "#4f7496",
    "--rr__color__primary-400": "#315779",
    "--rr__color__primary-500": "#13395c",
    "--rr__color__primary-600": "#0f2e4a",
    "--rr__color__primary-700": "#0b2237",
    "--rr__color__primary-800": "#081725",
    "--rr__color__primary-900": "#040b12",
    "--rr__color__primary-border-interactive-contained-default": "transparent",
    "--rr__color__primary-border-interactive-contained-hover": "transparent",
    "--rr__color__primary-border-interactive-contained-focus": "transparent",
    "--rr__color__primary-border-interactive-outlined-default": "#8bafd0",
    "--rr__color__primary-border-interactive-outlined-hover": "#8bafd0",
    "--rr__color__primary-border-interactive-outlined-focus": "transparent",
    "--rr__color__primary-border-interactive-text-default": "transparent",
    "--rr__color__primary-border-interactive-text-hover": "transparent",
    "--rr__color__primary-border-interactive-text-focus": "transparent",
    "--rr__color__primary-outline-interactive-contained-focus": "#a9cded",
    "--rr__color__primary-outline-interactive-outlined-focus": "#a9cded",
    "--rr__color__primary-outline-interactive-text-focus": "#a9cded",
    "--rr__color__primary-outline-input_control-focus": "#a9cded",
    "--rr__color__secondary-25": "#e0f9f5",
    "--rr__color__secondary-50": "#c1f3eb",
    "--rr__color__secondary-100": "#a2ede1",
    "--rr__color__secondary-200": "#82e6d7",
    "--rr__color__secondary-300": "#63e0cc",
    "--rr__color__secondary-400": "#43dac2",
    "--rr__color__secondary-500": "#24d4b8",
    "--rr__color__secondary-600": "#00c8a7",
    "--rr__color__secondary-700": "#0ea28a",
    "--rr__color__secondary-800": "#0b8d7a",
    "--rr__color__secondary-900": "#087c6c",
  },
  [AppEnum.AOSmithDefault]: {
    "--rr__color__primary-25": "#f3fcf7",
    "--rr__color__primary-50": "#e6f9f0",
    "--rr__color__primary-100": "#c0f0d9",
    "--rr__color__primary-200": "#92e5be",
    "--rr__color__primary-300": "#5fd7a1",
    "--rr__color__primary-400": "#2fbd7f",
    "--rr__color__primary-500": "#1aa766",
    "--rr__color__primary-600": "#138b55",
    "--rr__color__primary-700": "#0d7a3f",
    "--rr__color__primary-800": "#0d6140",
    "--rr__color__primary-900": "#0b5038",
    "--rr__color__primary-border-interactive-contained-default": "transparent",
    "--rr__color__primary-border-interactive-contained-hover": "transparent",
    "--rr__color__primary-border-interactive-contained-focus": "transparent",
    "--rr__color__primary-border-interactive-outlined-default": "#95c5b0",
    "--rr__color__primary-border-interactive-outlined-hover": "#95c5b0",
    "--rr__color__primary-border-interactive-outlined-focus": "transparent",
    "--rr__color__primary-border-interactive-text-default": "transparent",
    "--rr__color__primary-border-interactive-text-hover": "transparent",
    "--rr__color__primary-border-interactive-text-focus": "transparent",
    "--rr__color__primary-outline-interactive-contained-focus": "#b7e0cd",
    "--rr__color__primary-outline-interactive-outlined-focus": "#b7e0cd",
    "--rr__color__primary-outline-interactive-text-focus": "#b7e0cd",
    "--rr__color__primary-outline-input_control-focus": "#b7e0cd",
    "--rr__color__secondary-25": "#f3fcf7",
    "--rr__color__secondary-50": "#e6f9f0",
    "--rr__color__secondary-100": "#c0f0d9",
    "--rr__color__secondary-200": "#92e5be",
    "--rr__color__secondary-300": "#5fd7a1",
    "--rr__color__secondary-400": "#2fbd7f",
    "--rr__color__secondary-500": "#1aa766",
    "--rr__color__secondary-600": "#138b55",
    "--rr__color__secondary-700": "#0d7a3f",
    "--rr__color__secondary-800": "#0d6140",
    "--rr__color__secondary-900": "#0b5038",
  },
  [AppEnum.AOSmithStateWaterHeaters]: {
    "--rr__color__primary-25": "#f3fbfe",
    "--rr__color__primary-50": "#e6f7fc",
    "--rr__color__primary-100": "#bfeaf9",
    "--rr__color__primary-200": "#97d9f4",
    "--rr__color__primary-300": "#5fbfeb",
    "--rr__color__primary-400": "#2ea4de",
    "--rr__color__primary-500": "#1a8ccf",
    "--rr__color__primary-600": "#1475b8",
    "--rr__color__primary-700": "#00afe8",
    "--rr__color__primary-800": "#0077a3",
    "--rr__color__primary-900": "#005a7b",
    "--rr__color__primary-border-interactive-contained-default": "transparent",
    "--rr__color__primary-border-interactive-contained-hover": "transparent",
    "--rr__color__primary-border-interactive-contained-focus": "transparent",
    "--rr__color__primary-border-interactive-outlined-default": "#97c8de",
    "--rr__color__primary-border-interactive-outlined-hover": "#97c8de",
    "--rr__color__primary-border-interactive-outlined-focus": "transparent",
    "--rr__color__primary-border-interactive-text-default": "transparent",
    "--rr__color__primary-border-interactive-text-hover": "transparent",
    "--rr__color__primary-border-interactive-text-focus": "transparent",
    "--rr__color__primary-outline-interactive-contained-focus": "#c4e3ee",
    "--rr__color__primary-outline-interactive-outlined-focus": "#c4e3ee",
    "--rr__color__primary-outline-interactive-text-focus": "#c4e3ee",
    "--rr__color__primary-outline-input_control-focus": "#c4e3ee",
    "--rr__color__secondary-25": "#f3fbfe",
    "--rr__color__secondary-50": "#e6f7fc",
    "--rr__color__secondary-100": "#bfeaf9",
    "--rr__color__secondary-200": "#97d9f4",
    "--rr__color__secondary-300": "#5fbfeb",
    "--rr__color__secondary-400": "#2ea4de",
    "--rr__color__secondary-500": "#1a8ccf",
    "--rr__color__secondary-600": "#1475b8",
    "--rr__color__secondary-700": "#00afe8",
    "--rr__color__secondary-800": "#0077a3",
    "--rr__color__secondary-900": "#005a7b",
  },
  [AppEnum.AOSmithLowes]: {
    "--rr__color__primary-25": "#f3f6fd",
    "--rr__color__primary-50": "#e6ecfb",
    "--rr__color__primary-100": "#bfcef7",
    "--rr__color__primary-200": "#99aff2",
    "--rr__color__primary-300": "#5f85e9",
    "--rr__color__primary-400": "#2f5fdc",
    "--rr__color__primary-500": "#1a49c5",
    "--rr__color__primary-600": "#143aa5",
    "--rr__color__primary-700": "#062d87",
    "--rr__color__primary-800": "#072469",
    "--rr__color__primary-900": "#061d56",
    "--rr__color__primary-border-interactive-contained-default": "transparent",
    "--rr__color__primary-border-interactive-contained-hover": "transparent",
    "--rr__color__primary-border-interactive-contained-focus": "transparent",
    "--rr__color__primary-border-interactive-outlined-default": "#9db1d6",
    "--rr__color__primary-border-interactive-outlined-hover": "#9db1d6",
    "--rr__color__primary-border-interactive-outlined-focus": "transparent",
    "--rr__color__primary-border-interactive-text-default": "transparent",
    "--rr__color__primary-border-interactive-text-hover": "transparent",
    "--rr__color__primary-border-interactive-text-focus": "transparent",
    "--rr__color__primary-outline-interactive-contained-focus": "#c1cde7",
    "--rr__color__primary-outline-interactive-outlined-focus": "#c1cde7",
    "--rr__color__primary-outline-interactive-text-focus": "#c1cde7",
    "--rr__color__primary-outline-input_control-focus": "#c1cde7",
    "--rr__color__secondary-25": "#f3f6fd",
    "--rr__color__secondary-50": "#e6ecfb",
    "--rr__color__secondary-100": "#bfcef7",
    "--rr__color__secondary-200": "#99aff2",
    "--rr__color__secondary-300": "#5f85e9",
    "--rr__color__secondary-400": "#2f5fdc",
    "--rr__color__secondary-500": "#1a49c5",
    "--rr__color__secondary-600": "#143aa5",
    "--rr__color__secondary-700": "#062d87",
    "--rr__color__secondary-800": "#072469",
    "--rr__color__secondary-900": "#061d56",
  },
} as const;

export const URLS = {
  notFound: "/404",
  pdfViewer: (url: string) => `/pdf-viewer?url=${encodeURIComponent(url)}`,
  auth: {
    signIn: (queryParams?: {
      [AUTH_QUERY_PARAMS.INVITE_TEAM_CODE]?: string | null;
      [AUTH_QUERY_PARAMS.REDIRECT_URL]?: string | null;
    }) => {
      const newQueryParams = getRefinedQueryParams(
        [AUTH_QUERY_PARAMS.INVITE_TEAM_CODE, AUTH_QUERY_PARAMS.REDIRECT_URL],
        queryParams
      );

      return `/auth/signin${newQueryParams ? `?${newQueryParams}` : ""}`;
    },
    signOut: (queryParams?: {
      [AUTH_QUERY_PARAMS.REDIRECT_URL]?: string | null;
    }) => {
      const newQueryParams = getRefinedQueryParams(
        [AUTH_QUERY_PARAMS.REDIRECT_URL],
        queryParams
      );

      return `/auth/signout${newQueryParams ? `?${newQueryParams}` : ""}`;
    },
    verify: (queryParams?: {
      [AUTH_QUERY_PARAMS.VERIFY_METHOD]?: string | null;
      [AUTH_QUERY_PARAMS.VERIFY_VALUE]?: string | null;
      [AUTH_QUERY_PARAMS.INVITE_TEAM_CODE]?: string | null;
      [AUTH_QUERY_PARAMS.REDIRECT_URL]?: string | null;
    }) => {
      const newQueryParams = getRefinedQueryParams(
        [
          AUTH_QUERY_PARAMS.VERIFY_METHOD,
          AUTH_QUERY_PARAMS.VERIFY_VALUE,
          AUTH_QUERY_PARAMS.INVITE_TEAM_CODE,
          AUTH_QUERY_PARAMS.REDIRECT_URL,
        ],
        queryParams
      );

      return `/auth/verify${newQueryParams ? `?${newQueryParams}` : ""}`;
    },
    onboarding: (queryParams?: {
      [AUTH_QUERY_PARAMS.INVITE_TEAM_CODE]?: string | null;
      [AUTH_QUERY_PARAMS.REDIRECT_URL]?: string | null;
    }) => {
      const newQueryParams = getRefinedQueryParams(
        [AUTH_QUERY_PARAMS.INVITE_TEAM_CODE, AUTH_QUERY_PARAMS.REDIRECT_URL],
        queryParams
      );

      return `/auth/onboarding${newQueryParams ? `?${newQueryParams}` : ""}`;
    },
    termsAndConditions: (queryParams?: {
      [AUTH_QUERY_PARAMS.INVITE_TEAM_CODE]?: string | null;
      [AUTH_QUERY_PARAMS.REDIRECT_URL]?: string | null;
    }) => {
      const newQueryParams = getRefinedQueryParams(
        [AUTH_QUERY_PARAMS.INVITE_TEAM_CODE, AUTH_QUERY_PARAMS.REDIRECT_URL],
        queryParams
      );

      return `/auth/terms-and-conditions${newQueryParams ? `?${newQueryParams}` : ""}`;
    },
    pendingApproval: (queryParams?: {
      [AUTH_QUERY_PARAMS.REDIRECT_URL]?: string | null;
    }) => {
      const newQueryParams = getRefinedQueryParams(
        [AUTH_QUERY_PARAMS.REDIRECT_URL],
        queryParams
      );

      return `/auth/pending-approval${newQueryParams ? `?${newQueryParams}` : ""}`;
    },
  },
  home: "/home",
  project: {
    overview: (projectId: string) => `/projects/${projectId}/overview`,
    checklist: (
      projectId: string,
      queryParams?: {
        [PROJECT_CHECKLIST_QUERY_PARAMS.SELECTED_APPLICATION_ID]?: string;
        [PROJECT_CHECKLIST_QUERY_PARAMS.SELECTED_PHASE]?: string;
      }
    ) => {
      const newQueryParams = getRefinedQueryParams(
        [
          PROJECT_CHECKLIST_QUERY_PARAMS.SELECTED_APPLICATION_ID,
          PROJECT_CHECKLIST_QUERY_PARAMS.SELECTED_PHASE,
        ],
        queryParams
      );
      return `/projects/${projectId}/checklist${newQueryParams ? `?${newQueryParams}` : ""}`;
    },
    checklistItem: (projectId: string, item: string) =>
      `/projects/${projectId}/checklist?checklist_item_id=${item}`,
    incentives: (projectId: string) => `/projects/${projectId}/incentives`,
    applications: (projectId: string) => `/projects/${projectId}/applications`,
    equipment: (projectId: string) => `/projects/${projectId}/equipment`,
    teams: (projectId: string) => `/projects/${projectId}/teams`,
    wizard: {
      start(queryParams?: {
        [WIZARD_QUERY_PARAMS.QUOTABLE_CONTRACTOR_IDS]?: string | null;
      }) {
        const newQueryParams = getRefinedQueryParams(
          [WIZARD_QUERY_PARAMS.QUOTABLE_CONTRACTOR_IDS],
          queryParams
        );

        return `/projects/wizard/start${newQueryParams ? `?${newQueryParams}` : ""}`;
      },
      questions(
        sessionId: string,
        queryParams?: {
          [WIZARD_QUERY_PARAMS.QUOTABLE_CONTRACTOR_IDS]?: string | null;
        }
      ) {
        const newQueryParams = getRefinedQueryParams(
          [WIZARD_QUERY_PARAMS.QUOTABLE_CONTRACTOR_IDS],
          queryParams
        );

        return `/projects/wizard/${sessionId}/questions${newQueryParams ? `?${newQueryParams}` : ""}`;
      },
      bundle: (sessionId: string, bundleId: string) =>
        `/projects/wizard/${sessionId}/bundle/${bundleId}/selects`,
      bundleReport: (sessionId: string, bundleId: string) =>
        `/projects/wizard/${sessionId}/bundle/${bundleId}/report`,
      quotableJob: {
        contractors: (
          sessionId: string,
          quotableJobId: string,
          queryParams?: {
            [WIZARD_QUERY_PARAMS.QUOTABLE_CONTRACTOR_IDS]?: string | null;
          }
        ) => {
          const newQueryParams = getRefinedQueryParams(
            [WIZARD_QUERY_PARAMS.QUOTABLE_CONTRACTOR_IDS],
            queryParams
          );

          return `/projects/wizard/${sessionId}/quotable-job/${quotableJobId}/contractors${newQueryParams ? `?${newQueryParams}` : ""}`;
        },
        request: (
          sessionId: string,
          quotableJobId: string,
          queryParams?: {
            [WIZARD_QUERY_PARAMS.QUOTABLE_CONTRACTOR_IDS]?: string | null;
          }
        ) => {
          const newQueryParams = getRefinedQueryParams(
            [WIZARD_QUERY_PARAMS.QUOTABLE_CONTRACTOR_IDS],
            queryParams
          );

          return `/projects/wizard/${sessionId}/quotable-job/${quotableJobId}/request${newQueryParams ? `?${newQueryParams}` : ""}`;
        },
      },
    },
  },
  profile: "/profile",
  profilePages: {
    organizationSettings: "/profile/organization-settings",
    members: "/profile/members",
    me: "/profile/me",
    billing: "/profile/billing",
    about: "/profile/about",
  },
  projects: "/projects",
  teams: {
    list: "/teams",
    detail: (teamId: string) => `/teams/${teamId}`,
    invite: (token: string) => `/teams/invite/${token}`,
  },
  knowledgeCenter: {
    list: "/knowledge-center/list",
    detail: (knowledgeId: string) => `/knowledge-center/${knowledgeId}`,
  },
  contractorDirectory: {
    list: "/contractor-directory",
  },
  aosmith: {
    landing: (brand: string) => `/aos/${brand}`,
    questions: (brand: string, sessionId: string) =>
      `/aos/${brand}/verify/${sessionId}`,
    final: (brand: string, quotableJobId: string) =>
      `/aos/${brand}/final/${quotableJobId}`,
  },
} as const;

export const EXTERNAL_URLS = {
  terms: "https://www.rockrabbit.ai/documentation/terms-and-conditions",
  contractors: "https://www.rockrabbit.ai/customers/contractors",
};

export const QUERY_KEYS = {
  googleReview: {
    all() {
      return ["google_review"] as const;
    },
    placeAll() {
      return [...this.all(), "place"] as const;
    },
    placeDetail(placeId: string) {
      return [this.placeAll(), placeId] as const;
    },
  },
  analytics: {
    _base() {
      return ["ANALYTICS"] as const;
    },
    incentiveChanges(
      query: Parameters<typeof client.api.analyticsGetIncentiveAlerts>[0]
    ) {
      return [...this._base(), "INCENTIVE-CHANGES", query] as const;
    },
    _projectCounts() {
      return ["PROJECTS-COUNTS"] as const;
    },
    _projectAmounts() {
      return ["PROJECTS-AMOUNTS"] as const;
    },
    projectCountsOverTime() {
      return [...this._base(), ...this._projectCounts(), "OVER-TIME"] as const;
    },
    projectCountsByStatus() {
      return [...this._base(), ...this._projectCounts(), "BY-STATUS"] as const;
    },
    projectAmountsOverTime() {
      return [...this._base(), ...this._projectAmounts(), "OVER-TIME"] as const;
    },
    projectAmountsByStatus() {
      return [...this._base(), ...this._projectAmounts(), "BY-STATUS"] as const;
    },
  },
  root: {
    incentives() {
      return ["INCENTIVES"] as const;
    },
    applications(
      query?: Parameters<typeof client.api.applicationsGetApplications>[0]
    ) {
      return ["APPLICATIONS", query ?? {}] as const;
    },
    incentive(incentiveId: string) {
      return [...this.incentives(), incentiveId] as const;
    },
  },
  quote: {
    all() {
      return ["quote"] as const;
    },
    create(data: QuoteRequestCreate) {
      return [...this.all(), data] as const;
    },
    get(quotableJobId: string) {
      return [...this.all(), quotableJobId] as const;
    },
    listContractor(quotableJobId: string) {
      return [...this.get(quotableJobId), "listContractor"] as const;
    },
  },
  wizard: {
    _base() {
      return ["WIZARD"] as const;
    },
    _session(sessionId: string) {
      return [sessionId] as const;
    },
    currentQuestion(sessionId: string) {
      return [
        ...this._base(),
        this._session(sessionId),
        "CURRENT-QUESTION",
      ] as const;
    },
  },
  contractor: {
    all() {
      return ["contractor"] as const;
    },
    listServices(
      query?: Parameters<typeof client.api.contractorsAllServiceTypes>[0]
    ) {
      return [...this.all(), "service_list", query] as const;
    },
    listProgramOptions(contractorId: string) {
      return [...this.all(), "program_options", contractorId] as const;
    },
    listCertifications() {
      return [...this.all(), "certification_list"] as const;
    },
    listAll() {
      return [...this.all(), "list"] as const;
    },
    list(query?: Parameters<API["contractorsFindContractors"]>[0]) {
      return [...this.listAll(), query] as const;
    },
    singleAll() {
      return [...this.all(), "single"] as const;
    },
    detail(contractorId: string) {
      return [...this.singleAll(), "detail", contractorId] as const;
    },
    self(contractorId: string) {
      return [...this.singleAll(), "self", contractorId] as const;
    },
    paymentPortal(contractorId: string, returnUrl: string) {
      return [
        ...this.all(),
        "payment_portal",
        contractorId,
        returnUrl,
      ] as const;
    },
  },
  bundle: {
    all() {
      return ["bundle"] as const;
    },
    detailAll() {
      return [...this.all(), "detail"] as const;
    },
    detail(bundleId: string) {
      return [...this.detailAll(), bundleId] as const;
    },
    previewAll() {
      return [...this.all(), "preview"] as const;
    },
    preview(bundleId: string) {
      return [...this.previewAll(), bundleId] as const;
    },
    incentivesAll(bundleId: string) {
      return [...this.all(), "incentives", bundleId] as const;
    },
    incentives(bundleId: string, query: IncentiveBundleListIncentivesRequest) {
      return [...this.incentivesAll(bundleId), query] as const;
    },
  },
  user: {
    _base() {
      return ["USER"] as const;
    },
    info() {
      return [...this._base(), "PROFILE"] as const;
    },
    orgs() {
      return [...this._base(), "ORGS"] as const;
    },
    termsOfConditionsHtml() {
      return [...this._base(), "terms_of_conditions_html"] as const;
    },
    contractor({
      licenseNumber,
      state,
    }: {
      licenseNumber: string;
      state: SupportedUSStatesEnum | null;
    }) {
      return [...this._base(), "CONTRACTOR", { licenseNumber, state }] as const;
    },
  },
  project: {
    root: {
      all() {
        return ["project"] as const;
      },
      listAll() {
        return [...this.all(), "list"] as const;
      },
      list(query?: Parameters<API["projectsListProjects"]>[0]) {
        return [...this.listAll(), query] as const;
      },
      detailAll() {
        return [...this.all(), "detail"] as const;
      },
      detail(projectId: string) {
        return [...this.detailAll(), projectId] as const;
      },
    },
    team: {
      all(projectId: string) {
        return ["project.team", projectId] as const;
      },
      listAll(projectId: string) {
        return [...this.all(projectId), "list"] as const;
      },
    },
    checklist: {
      all(projectId: string) {
        return ["project.checklist", projectId] as const;
      },
      listAll(projectId: string) {
        return [...this.all(projectId), "list"] as const;
      },
      list(
        projectId: string,
        query?: Parameters<API["projectsGetChecklist"]>[1]
      ) {
        return [...this.listAll(projectId), query] as const;
      },
      detailAll(projectId: string, checklistItemId: string) {
        return [...this.all(projectId), checklistItemId, "detail"] as const;
      },
      detail(
        projectId: string,
        checklistItemId: string,
        query?: Parameters<API["projectsGetChecklistItem"]>[2]
      ) {
        return [...this.detailAll(projectId, checklistItemId), query] as const;
      },
      isAnyApplicationsReadyToSubmit(projectId: string) {
        return [
          ...this.all(projectId),
          "any_applications_ready_to_submit",
        ] as const;
      },
      isPhaseReadyToSubmitAll(projectId: string) {
        return [...this.all(projectId), "phase_ready_to_submit"] as const;
      },
      isPhaseReadyToSubmit(
        projectId: string,
        applicationId: string,
        phaseName: ApplicationPhaseEnum
      ) {
        return [
          ...this.isPhaseReadyToSubmitAll(projectId),
          applicationId,
          phaseName,
        ] as const;
      },
      isPhaseReadyToExportAll(projectId: string) {
        return [...this.all(projectId), "phase_ready_to_export"] as const;
      },
      isPhaseReadyToExport(
        projectId: string,
        query?: Parameters<API["projectsIsReadyForExport"]>[1]
      ) {
        return [...this.isPhaseReadyToExportAll(projectId), query] as const;
      },
    },
    application: {
      all(projectId: string) {
        return ["project.application", projectId] as const;
      },
      listAll(projectId: string) {
        return [...this.all(projectId), "list"] as const;
      },
      list(
        projectId: string,
        query?: Parameters<API["projectsGetProjectApplications"]>[1]
      ) {
        return [...this.listAll(projectId), query] as const;
      },
      detailAll(projectId: string) {
        return [...this.all(projectId), "detail"] as const;
      },
      detail(projectId: string, applicationId: string) {
        return [...this.detailAll(projectId), applicationId] as const;
      },
    },
    phaseUpdate: {
      all(projectId: string) {
        return ["project.phase_updates", projectId] as const;
      },
      listAll(projectId: string) {
        return [...this.all(projectId), "list"] as const;
      },
      list(
        projectId: string,
        applicationId: string,
        query?: Parameters<API["projectsGetPhaseUpdates"]>[2]
      ) {
        return [...this.listAll(projectId), applicationId, query] as const;
      },
    },
  },
  teams: {
    _base() {
      return ["TEAMS"] as const;
    },
    _projects() {
      return ["PROJECTS"] as const;
    },
    list() {
      return [...this._base(), "LIST"] as const;
    },
    _team(teamId: string) {
      return [...this._base(), teamId] as const;
    },
    _user(userId: string) {
      return ["USER", userId] as const;
    },
    team(teamId: string) {
      return this._team(teamId);
    },
    teamProjects(teamId: string) {
      return [...this._team(teamId), ...this._projects()] as const;
    },
  },
  invite: {
    _base() {
      return ["INVITE"] as const;
    },
    invite(inviteToken: string) {
      return [...this._base(), inviteToken] as const;
    },
  },
  utils: {
    all() {
      return ["utils"] as const;
    },
    ahjAll() {
      return [...this.all(), "ahj"] as const;
    },
    ahj(address: string) {
      return [...this.ahjAll(), address] as const;
    },
    supportedLocales() {
      return [...this.all(), "supported_locales"] as const;
    },
  },
};
