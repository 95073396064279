import { AOS_BRAND_TO_APP_ENUM, AppEnum } from "@/constants";

export const isSVCE = (hostname: string) =>
  // new RegExp(`^${process.env.NEXT_PUBLIC_SVCE_URL}$`).test(location.hostname);
  "svce.gorockrabbit.com" === hostname ||
  "svce.gorockrabbit.net" === hostname ||
  "assisted.svcleanenergy.org" === hostname ||
  "svce-demo.gorockrabbit.com" === hostname;

export const isAOSmithDefault = (pathname: string) => {
  const targetAppEnum = Object.entries(AOS_BRAND_TO_APP_ENUM).find(
    ([, appName]) => appName === AppEnum.AOSmithDefault
  );

  if (!targetAppEnum) {
    return false;
  }

  return new RegExp(`^/aos/${targetAppEnum[0]}`).test(pathname);
};

export const isAOSmithStateWaterHeaters = (pathname: string) => {
  const targetAppEnum = Object.entries(AOS_BRAND_TO_APP_ENUM).find(
    ([, appName]) => appName === AppEnum.AOSmithStateWaterHeaters
  );

  if (!targetAppEnum) {
    return false;
  }

  return new RegExp(`^/aos/${targetAppEnum[0]}`).test(pathname);
};

export const isAOSmithLowes = (pathname: string) => {
  const targetAppEnum = Object.entries(AOS_BRAND_TO_APP_ENUM).find(
    ([, appName]) => appName === AppEnum.AOSmithLowes
  );

  if (!targetAppEnum) {
    return false;
  }

  return new RegExp(`^/aos/${targetAppEnum[0]}`).test(pathname);
};
